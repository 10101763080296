.term {
    margin-top: 128px;
    margin-bottom: 100px;
}

.term h2 {
    font-size: 24px;
    font-weight: 400;
    color : #00345A;
}

.head {
    font-size: 32px !important;
    font-weight: 400;
    color : #00345A;
}

.term p {
    margin-top: 20px;
    color : #00345A;
}

.term h5 {
    font-size: 18px;
    font-weight: 400;
    color : #00345A;
    margin-top: 20px;
} 

.termContent {
    margin-bottom: 80px;
}