$box-shadow-color: rgba(0, 0, 0, 0.15);

$button-text-color: #fff;
$primary-button-bg-color: linear-gradient(
  274deg,
  #00345a 9.14%,
  #33678d 93.91%
);

$text-heading-color: #00345a;
$text-title-color: #354147;
$text-body-color: #697175;
$text-white-color: #fff;
$text-grey-color: #99b3c6;
$text-inactive-color: #8e9599;
$text-tip-color: #b4babd;
$text-error-color: #ff5353;
$text-link-color: #4ba3ff;

$default-bg-color: #fafafa;
$white-bg-color: #fff;

$separator-bg-color: #4ba3ff;
$tag-active-bg-color: #4ba3ff;
$tag-weaken-bg-color: #b7daff;
$tag-inactive-bg-color: #edf1f2;

$input-default-border-color: #b4babd;
$input-active-border-color: gray;
$input-error-border-color: #ff5353;

$input-phone-placeholder-color: #99b3c6;
$input-phone-default-border-color: #99b3c6;
$input-phone-active-border-color: #4ba3ff;
$input-phone-error-border-color: #ff5353;

$input-prefix-border-color: #354147;

$character-cursor-color: #354147;
$character-bg-color: #fff;
$character-border-color: #d6dbdd;
$character-border-active-color: #4ba3ff;

$alert-primary-color: #eef6ff;
