.navBar {
  height: 88px;
  background: transparent;
  display: flex;
  z-index: 999;
  width: 100%;
  position: fixed;
  max-width: 1920px;
  top: 0;
  -webkit-transition: background-color 1s; /* For Safari 3.1 to 6.0 */
  transition: background-color 1s;
}

.scrolled{
    background-color: #FAFAFA !important;
    -webkit-transition: background-color 1s; /* For Safari 3.1 to 6.0 */
    transition: background-color 1s;
    /* box-shadow: 0 4px 24px rgb(1 24 36 / 12%); */
}

.logoLink {
    cursor: pointer;
}

.nolink {
    cursor: default;
}

.logo-img {
    width: 100%;
  height: auto;
  margin-top: 21px;
}

.nav-list {
    display: flex;
    justify-content: center;
    padding-left: 0px !important;
    list-style-type: none;
}

.nav-listItem {
    display: inline-block;
  line-height :88px;
  margin-right: 40px;
}

.nav-listItem a {
    position: relative;
  font-weight: 400;
  font-size: 18px;
  padding: 5px 0;
  color: white;
  text-decoration: none;
}


.dropdown-scroll a {
    color: #00345A !important;
    font-weight: 400 !important;
}

.nav-listItem a:hover {
    color: white;
}

.nav-listItem a:before {
    position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background: white;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .4s linear;
}

.nav-listItem a:hover:before {
    transform: scaleX(1);
    transform-origin: left;
}

.dropdown-list a:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background: #00345A;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform .4s linear;
}

.dropdown-list a:hover:before {
    transform: scaleX(1);
    transform-origin: left;
}


.personal-loan {
    font-weight: 400;
    font-size: 18px;
    color: white;
    margin: 0;
    cursor: pointer;
}

.dropdown-scroll {
    color: #00345A !important;
    font-weight: 400 !important;
}

.dropdown {
    padding:0px;
}


.dropdown-list a{
    color: #00345A;
    font-weight: 400;
    font-size: 18px;
}

.dropdown-list a:hover {
    color: #00345A;
}

.burger-menu{
    margin-top: 18px;
}

.burger {
    width: 25px ;
    height: 25px ;
    cursor: pointer;
  }

  .nav-lang {
      list-style-type: none;
  }


.download-btn {
     display: flex;
     padding: 0px 13px;
     color: white;
     margin-top: 19px;
     width: 100%;
     font-weight: 400;
     font-size: 18px;
     text-align: center;
     height:50px;
     line-height: 50px;
     border-radius:40px;
     background: linear-gradient(to left ,rgba(0, 52, 90, 1),rgba(51, 103, 141, 1));
     box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
     cursor: pointer;
 }

.download-btn-cn {
    padding: 0px 33px;
}

 .download-icons {
     margin-left: 8px;
     height: auto;
     margin-top: -2px;
 }

 .download-row-small {
     display: none;
 }

 .download-small{
    margin-top: 9px;
    cursor: pointer;
    margin-right: 20px;
}

.popover {
    margin-top: -15px;
}



.download-smBtn{
    width: 116px;
    height: 30px;
    background-color: transparent;
    border-radius: 40px;
    color: #fff;
    border: 2px solid #fff;
    font-size: 16px;
    font-weight: 400;
    padding-top: 2px;
}

.download-toggled {
    width: 116px;
    height: 30px;
    background: linear-gradient(to left ,rgba(0, 52, 90, 1),rgba(51, 103, 141, 1));
    border: 2px solid;
    border-radius: 40px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding-top: 2px;
}

 .language{
    display: flex;

 }

 .localization{
     display: flex;
 }

 .lang-line{
     height: 28px;
     margin: 32px 10px;
     width: 3px;

 }

 .change-lang{
     color: white;
     line-height:88px;
     font-size: 18px;
     cursor: pointer;
 }

 .change-langdown{
     color: #00345A !important;
 }

 .localization {
    color: #00345A;
    line-height:88px;
    font-size: 18px;
    cursor: pointer;
 }

 .i18lang {
     display: none;
 }

 .abc {
     margin-bottom: 188px;
 }

 .xyz {
    margin-bottom: 82px;
}

 .category {
    width: 18px;
    height: 10px;
    margin-top: 24px;
    margin-left: 6px;
    display: none;
    
}

/* @media (min-width: 1122) {
    .personalLoan {
        display: flex !important;
        justify-content: center !important;
        text-align: center !important;
    }
} */

.personalLoan {
    display: flex;
    justify-content: center;
}

.popover-body {
    text-align: center !important;
}

.gray {
    background-color: #FAFAFA !important;
    -webkit-transition: background-color 1s; /* For Safari 3.1 to 6.0 */
    transition: background-color 1s;  
}


@media (max-width: 1200px) {
    
    .download-row-small {
        display: flex;
    }
    .nav-list{
            margin-left: 0px !important;
            position: fixed;
            width: 100%;
            height:calc(100vh - 88px);
            background-color: #FAFAFA;
            margin: 88px 0 0 0;
            text-align: center;
            right : -100%;
            transition: all .5s;
            align-items: left;
            flex-direction:column;
    }
    .nav-listItem{
        line-height: 55px;
        display: flex;
        flex-direction: row;
        margin-left: 15px !important;
        margin-right: 0;
        
    }
    .nav-listItem a {
        color: #00345A !important;
    }
    .personal-loan{
        color: #00345A !important;
        font-weight: 500 !important;
    }

    .burger-menu {
        text-align: right;
        margin-top: 10px;
        margin-right: 20px;
    }

    .download-small{
        /* float: right; */
    }
    
    .dealMobileLang {
        display: flex;
        justify-content: flex-end;
    }
     
    .download-row{
        display: none;
    }

    .i18lang {
        display: block;
        margin-left: 15px;
    }

    .language{
        justify-content: center;
        margin-top: -18px;
        float: left;
    }
    .change-lang {
        color: #00345A;
    }
    .category{
        display: block;
    }

    .popover{
        z-index: 1070 !important;
    display: block !important;
    max-width: 276px !important;
    font-family: var(--bs-font-sans-serif) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 2.0 !important;
    text-align: left !important;
    text-align: center !important;
    text-decoration: none !important;
    text-shadow: none !important;
    text-transform: none !important;
    letter-spacing: normal !important;
    word-break: normal !important;
    word-spacing: normal !important;
    white-space: normal !important;
    line-break: auto !important;
    font-size: .875rem !important;
    word-wrap: break-word !important;
    background-color: #FAFAFA !important;
    color: #00345A !important;
    border: none !important;
    /* transform: translate3d(15px, 70px, 0px) !important; */
}
    .popover .popover-arrow {
        display: none !important; 
    }
    .dropdown{
        margin-top: -30px;
    }
    .popover {
        margin-top: 0px;
    }

    .xyz .popover {
        position: absolute;
        inset: 0px auto auto 0px;
        transform: translate3d(15px, 63px, 0px) !important;
    }

    .abc .popover {
        position: absolute;
        inset: 0px auto auto 0px;
        transform: translate3d(15px, 63px, 0px) !important;
    }

    .engMar .popover {
        position: absolute;
        inset: 0px auto auto 0px;
        transform: translate3d(0px, 63px, 0px) !important;
    }

    .chiMArgin .popover {
        position: absolute;
        inset: 0px auto auto 0px;
        transform: translate3d(0px, 63px, 0px) !important;
    }

    .popover-body {
        text-align: left !important;
        padding: 1rem 0rem !important;
    }

    .personalLoan {
        display: flex;
        justify-content: left;
    }
}

@media only screen and (max-width: 768px) {
    .logo-img {
        width: 116px;
    }
}

@media only screen and (max-width: 650px) {
    .download-row-small {
        padding-right: 16px !important;
    }
}
@media only screen and (max-width: 620px) {
    .download-row-small {
        padding-right: 20px !important;
    }
}

@media only screen and (max-width: 576px) {
    .download-row-small {
        padding-right: 26px !important;
    }
}

@media only screen and (max-width: 555.9px) {
    .download-row-small {
        padding-right: 30px !important;
    }
}

@media only screen and (max-width: 535.9px) {
    .download-row-small {
        padding-right: 34px !important;
    }
}
@media only screen and (max-width: 515.9px) {
    .download-row-small {
        padding-right: 38px !important;
    }
}
@media only screen and (max-width: 495.9px) {
    .download-row-small {
        padding-right: 43px !important;
    }
}
@media only screen and (max-width: 475.9px) {
    .download-row-small {
        padding-right: 48px !important;
    }
}
@media only screen and (max-width: 440.9px) {
    .download-row-small {
        padding-right: 54px !important;
    }
}

@media only screen and (max-width: 425px) {
    .download-smBtn {
        width: 116px;
        height: 30px;
        background-color: transparent;
        border-radius: 40px;
        color: #fff;
        border: 2px solid #fff;
        font-size: 16px;
        font-weight: 400;
        padding-top: 2px;
        text-align: right;
    }
    .burger-menu {
        margin-top: 10px;
        margin-right: 10px;
    }
    .download-row-small {
        padding-right: 58px !important;
    }
    .download-toggled {
        width: 116px;
        height: 30px;
        background: linear-gradient(to left ,rgba(0, 52, 90, 1),rgba(51, 103, 141, 1)) border-box;
        border: 2px solid transparent;
        border-radius: 40px;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        padding-top: 2px;
    }
}

@media only screen and (max-width: 400px) {
    .download-row-small {
        padding-right: 64px !important;
    }
}

@media only screen and (max-width: 375px) {
    .download-row-small {
        padding-right: 68px !important;
    }
}

@media only screen and (max-width: 350px) {
    .download-row-small {
        padding-right: 74px !important;
    }
}


.dropdown .nav-listItem {
    line-height: 30px !important;
}

@media (min-width: 1122px) {

    .engMar .popover {
        position: absolute !important;
    inset: 0px auto auto 0px !important;
    transform: translate(256px, -2px) !important;
}

.engMar .popover-body {
    width: 240px !important;
}

.engMar .bs-popover-end>.popover-arrow::before {
    top: -20px !important
}

.engMar .bs-popover-end>.popover-arrow::after {
    top: -20px !important
}

.chiMArgin .popover  {
        position: absolute !important;
    inset: 0px auto auto 0px !important;
    transform: translate(139px, -0px) !important;
}

.chiMArgin .popover-body {
    width: 165px !important;
}

.chiMArgin .bs-popover-end>.popover-arrow::before {
    top: -20px !important
}

.chiMArgin .bs-popover-end>.popover-arrow::after {
    top: -20px !important
}
}


.giveMargin {
    margin-bottom: 85px !important;
}

.noMargin {
    margin-bottom: 0 !important;
}

.set-font {
    font-weight: 500 !important;
}

.subImg {
    margin-top: 15px !important;
}

@media only screen and (max-width: 400px) {
    .burger-menu {
        margin-right: 16px;
    }
    
}
