.faq-tabs {
    margin-top: 60px;
}

.faqHead {
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    color: #00345A;
}

.faqDesc {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    align-items: center;
    color: #00345A;
}

.nav-tabs li {
    width: 20% !important;
}

.nav-tabs .nav-link.active {
    width: 100%!important;
    height: 44px!important;
    margin-bottom: -1px;
    color: #fff !important;
    border-radius: 40px!important;
    border: none !important;
    border-top-right-radius: 0.25rem;
    margin-right: 7px!important;
    background: linear-gradient(to left ,rgba(0, 205, 231, 1), rgba(75, 163, 255, 1)) !important;
}

.nav-tabs .nav-link{
    width: 100% !important;
    height: 44px!important;
    margin-bottom: -1px;
    background: transparent;
    color: #99B3C6;
    border-radius: 40px!important;
    border-top-right-radius: 0.25rem;
    margin-right: 7px!important;
}

.nav-tabs {
    border-bottom: none !important;
    
}

.nav-item {
    margin-right: 10px !important;
}

.faq-desc li {
    list-style-type: disc !important;
}

@media (max-width: 992px) {
    .nav-tabs .nav-link {
        margin-bottom: 10px !important;
    }
}

.help-container {
    margin-top: 16px;
    margin-bottom: 16px;
    cursor: pointer;
}

.help-container h4 {
    color: #00345A;
    font-size: 22px;
    font-weight: 400;
}

.faq-desc {
    font-size: 16px;
    font-weight: 400;
    color: #00345A;
    margin-top: 20px;
}

.cursor {
    cursor: pointer;
}

@media (max-width: 576px) {
    .faq-tabs {
        margin-top: 40px  !important; 
    }
}

@media (max-width: 460px) {
    .tabsArea {
        padding: 0 10px !important;
    }
}

 @media (max-width: 1199.9px) {
    .nav-tabs li {
        width: 100% !important;
    }

    .nav-tabs {
        overflow-x: auto;
        overflow-y:hidden;
        flex-wrap: nowrap;
    }

    .nav-tabs .nav-link {
        white-space: nowrap;
    }
}

.faqQuestionC {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #99B3C6;
    margin-top: 4px;
}

.faqQuestionC:hover {
    color: #00345A !important;
}