.opportunitesToogle {
    margin-top: 30px;
    margin-bottom: -10px;
}

.rightarrow {
    cursor: pointer;
}

.opportunityDesc {
    color: #00345A;
}

.opportunityDesc p {
    font-size: 16px;
    font-weight: 400;
    color: #00345A;
    margin-top: 10px;
}

.opportunityDesc h5 {
    font-size: 18px;
    font-weight: 400;
    color: #00345A;
    margin-top: 15px;
}

.opportunityDesc li {
    font-size: 16px;
    font-weight: 400;
    color: #00345A;
}

.linkedin {
    width: 100%;
    height: 48px;
    background: linear-gradient(to left ,rgba(0, 52, 90, 1),rgba(51, 103, 141, 1));
    border-radius: 40px;
    padding-top: 15px;
    margin-top: 30px;
    cursor: pointer;
}

.linkedin-btn {
    font-size: 18px !important;
    color: #fff;
    font-weight: 400;
    
}

.joinus-toggled {
    font-size: 22px;
    font-weight: 400;
    background: linear-gradient(to left ,rgba(0, 205, 231, 1), rgba(75, 163, 255, 1));
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}