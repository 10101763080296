@import 'styles/size.scss';
@import 'styles/colors.scss';
@import 'styles/font.scss';

.container {
  gap: $base-size * 1.25;
  width: fit-content;
}

.character {
  width: 47px;
  height: 53px;
  line-height: 53px;

  border: 1px solid $character-border-color;
  border-radius: $base-radius;

  background-color: $character-bg-color;

  font-size: $h4-font-size;
  font-weight: $font-medium;

  outline: none;
  color: $text-title-color;
}

.characterSelected {
  position: relative;
  border: 1px solid $character-border-active-color;

  color: $text-title-color;

  &:not(.characterFilled) {
    color: transparent;
  }

  &:not(.characterFilled)::after {
    content: '';
    width: 1px;
    height: 22px;
    background-color: $character-cursor-color;
    display: block;
    position: absolute;
    top: 50%;
    left: 49%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
