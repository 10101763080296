.slick-dots li button:before {
    font-size: 12px !important;
    color: #4BA3FF !important;
}

.slick-dots li.slick-active button:before {
    color: #4BA3FF !important;
}

.caresoleImg {;
    padding: 0px;
}

.caresoleImg img {
    width: 100%;
    border-radius: 0px 10px 10px 0px;
}

.caresoleSection .slick-slide {
    height: unset !important;
}

.carouselText {
    background: white;
    padding-left: 30px;
    padding-right: 20px;
    border-radius: 10px 0px 0px 10px;
}

.carouselTitle {
    margin-top: 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #00CDE7;
}

.carouselDesc {
    font-weight: 500;
    color: #00345A;
    font-size: 26px;
    line-height: 36px;
}

.slick-dots {
    bottom: 20px;
    text-align: left;
    margin-left: 30px;
}

.slick-dots li {
    width: 10px;
    height: 10px;
}

.slick-dots li button::before {
    font-size: 16px;
    color: #99B3C6 !important;
    opacity: 0.3;
}

.slick-dots li.slick-active button::before {
    color: #99B3C6 !important;
    opacity: 1;
}

.mobileCarouselText {
    display: none
}

@media (max-width: 768px) {
    .caresoleImg img {
        width: 100%;
        border-radius: 10px;
    }

    .mobileCarouselText {
        display: block;
        background: white;
        border-radius: 10px 10px 10px 10px;
        padding-bottom: 70px;
    }
    .carouselText {
        display: none;
    }

    .slick-dots {
        bottom: 20px;
        text-align: left;
        margin-left: 10px;
    }
}