.blogs-margin {
    margin-top: 148px;
}

.blogslist {
    margin: 50px 0 100px 0;
}

.blogs h1 {
    font-size: 56px;
    font-weight: 400;
    background: linear-gradient(#00CDE7 15.42%, #4BA3FF 87.08%);
    background: -webkit-linear-gradient(#00CDE7 15.42%, #4BA3FF 87.08%);
    background-clip: border-box;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.blogs p {
    font-size: 16px;
    font-weight: 400;
    color: #00345A;
    margin-top: 20px;
}

.categoryButton {
    width: max-content;
    background: transparent;
    color: #00CDE7;
    border-radius: 60px;
    padding: 10px 20px;
    border-color: #00CDE7;
}

.categoryButton:hover {
    background: #00CDE7 !important;
    color: white !important;
    border-color: #00CDE7 !important;
}

.categoryButton.active {
    background: #00CDE7 !important;
    color: white !important;
    border-color: #00CDE7 !important;
}

@media (max-width: 576px) {
    .blogs h1 {
        font-size: 32px !important;
    }
}