.button {
  position: relative;

  cursor: pointer;
  user-select: none;
  transition: linear 0.5s all;

  &:active {
    opacity: 0.5;
  }

  &.fullSize {
    width: 100%;
  }

  &.loading {
    opacity: 0.5;
    cursor: progress;
  }
}
