
*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.link {
  text-decoration: none;

}

body {
  font-family: 'Circular Std', sans-serif !important;
  background-color: #FAFAFA !important;
  max-width: 1920px; 
  margin: 0 auto !important;
}


