@import 'styles/colors.scss';
@import 'styles/size.scss';

.button {
  border-radius: $base-radius * 5;
  background: $primary-button-bg-color;
  box-shadow: 0px 10px 20px 0px $box-shadow-color;

  width: fit-content;
  padding: 13px 68.5px;

  position: relative;
  text-wrap: nowrap;
  text-align: center;

  cursor: pointer;
  user-select: none;
  transition: linear 0.5s all;

  &:active {
    opacity: 0.5;
  }

  &.fullSize {
    width: 100%;
  }

  &.loading {
    opacity: 0.8;
    cursor: progress;
  }

  &.disable {
    opacity: 0.4;
    cursor: default;
  }

  .spinner {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateX(-100%) translateY(-50%);
  }
}

@media (max-width: $web-responsive-width) {
  .button {
    &.lgFullSize {
      width: 100%;
    }
  }
}
