.blogClick {
    cursor: pointer;
}

.dealCard {
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
    margin: 0px 18px 64px 18px;
    min-width: 340px;
    max-width: 340px;
    cursor: pointer;
    padding-bottom: 16px !important;
}

.dealImg {
    width: 100%;
    max-width: 340px;
    height: 160px;
    /* height: auto; */
    background-repeat: no-repeat;
    border-radius: 12px 12px 0px 0px;
}

.deal-content {
    margin: 28px 16px 0px 16px;
}

.dealCategoryText {
    color: #00B9D0;
    font-size: 14px;
    margin-bottom: 0px !important;
}

.dealTitle {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #354147;
    line-height: 28px;
}

.dealDesc {
    font-size: 12px;
    font-weight: 400;
    color: #697175;
    margin-top: 14px;
    height: 40px;
}

.dealButton {
    width: max-content;
    background: linear-gradient(274.24deg, #00345A 9.14%, #33678D 93.91%);    
    border-radius: 60px;
    align-items: center;
    color: white;
    border-color: #33678D;
    height: auto;
    vertical-align: bottom;
    margin-top: 16px;
}

.dealHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-flow: row wrap;
}

@media (max-width: 576px) {

    .dealCard {
        min-width: 300px;
        max-width: 320px;
        margin: 0px auto 24px auto;
    }

}

@media (max-width: 320px) {

    .dealCard {
        margin: 0px auto 24px auto;
        max-width: 300px;
    }

}