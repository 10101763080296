@import 'styles/size.scss';
@import 'styles/colors.scss';


.tag {
  width: 100%;
  padding: $base-size * 2;
  background-color: $tag-inactive-bg-color;
  border-radius: 2px;
  
  cursor: pointer;
  user-select: none;
  transition: linear 0.5s all;

  &:active {
    opacity: 0.5;
  }
  
  &.active {
    background-color: $tag-active-bg-color;
  }
}


@media (max-width: $web-responsive-width) {
  .tag {
    text-align: center;

    padding: $base-size * 2 $base-size;
  }
}