@import 'styles/size.scss';
@import 'styles/colors.scss';

.background {
  // nav header height
  padding-top: 88px;

  background: linear-gradient(
    359deg,
    #bbf2f9 -27.33%,
    #ecfcff 45.99%,
    #fafafa 77.75%
  );

  display: flex;
  flex-direction: column;

  // 100vh - head - footer
  min-height: calc(100vh - 387px);

  // 60px footer -margin and 120px bottom
  padding-bottom: 60px + 120px;
}

.card {
  max-width: 590px;
  width: 100%;

  margin: 0 auto;
  margin-top: $base-size * 5;

  padding: $base-size * 5;

  border-radius: $base-radius * 2.5;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $base-size * 2.5;

  background-color: $white-bg-color;
}

.button {
  max-width: 230px;
}

@media (max-width: $web-responsive-width) {
  .background {
    padding-left: $base-size * 2.5;
    padding-right: $base-size * 2.5;

    // 60px footer -margin and 80px bottom
    padding-bottom: 60px + 80px;
  }

  .card {
    margin-top: 0px;
    padding: $base-size * 2.5 $base-size * 2;
  }

  .button {
    max-width: 100%;
  }
}
