.card {
    padding: 39px 35px 35px 40px;
    background-color: #fff;
    width: 100%;
    height: 313px;
    position: relative;
}

.card h6 {
    font-size: 16px;
    font-weight: 500;
    color: #00345A;
}

.card p {
    font-size: 16px;
    font-weight: 300;
    color: #00345A;
    margin-top: 15px;
}

.card ul li {
    display: inline-block;
    margin-left: 9px;
}

.thumbnil {
    margin-top: -26px;
}

.app-store{
    margin-top: -7px !important;
    color: rgb(163, 163, 163) !important;
    font-size :14px !important;
}

.intro {
    position: absolute;
    bottom: 0;
}

@media (max-width: 576px) {
    .card {
        padding: 20px;
    }
}