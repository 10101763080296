.footer {
    width: 100%;
    height: auto;
    background: url('./images/footer.svg');
    position: absolute;
    margin-top: -100px;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1920px;
}

.linkList {
    list-style-type: none;
}

.footerleft{
   padding-top: 68px;
    /* padding-left: 41px; */
}

.footer-logo{
    width: 147px;
    height: 40px;
}

.address {
    margin-top: 101px;
}

.address p {
    font-weight: 400;
    color: #fff;
    font-size: 16px;
    margin-top: -10px;
}

.no-break {
  white-space: nowrap;
}

.footer-right {
    display: flex;
    flex-direction: column;
}
.social-links {
    margin-left: 40px;
    margin-top: 138px;
}

.social-links ul {
    display: flex;
    float: right;
}

.social-links ul .link {
    display: inline-block;
    cursor: pointer;
    margin-right: 24px;
}

.social-icon {
    width: 30px;
    height: 30px;
}

.footer-links{
   margin-top: 50px;
}

.footer-links ul{
  display: flex;
  float: right
}

.footerlinks {
    margin-right: 40px;
    color: #fff;
    cursor: pointer;
    font-weight: 400;
    font-size: 20px;
}

.footer-links ul .link:hover {
    color: #fff;
}

.horizontalline{
    text-align: center;
}

.footerline {
    margin-top: 32px;
    margin-bottom: 36px;
    width: 95%;
}

.warning {
    color: #fff;
    font-size: 32px;
    width: 95%;
    margin: auto;
}

.license{
    color: #fff;
    font-size: 16px;
    font-weight:400;
    margin: auto;
    width: 95%;
    margin-top: 5px;
    margin-bottom: 30px;
}

.announcementStyle {
    margin-bottom: 84px;
}

.appStoreLinks {
    display: flex;
    float: right;
    margin-right: 40px;
}

@media (max-width: 768px) {
    .social-links {
        margin-top: 25px;
        padding: 0 !important;
        margin-left: 0;
    }
    .social-links ul {
        float: left;
        padding: 0;
    }
    .footerlinks{
    font-size: 16px;
    }
    .footer-links ul {
        display: block;
        float: left;
        padding: 0;
    }
    .footer-list{
        padding-top: 20px;
    } 
    .footerleft {
        padding-left: 10px;
    }
    .horizontalline {
        padding: 12px;
    }
    .licence {
        margin-left: 0px;
        padding : 0;
    }
    .appStoreLinks {
        float: left;
        margin-top: 5px;

    }
}
@media (max-width: 768px) {
     .license {
        margin-left: 0px;
        margin-top: 20px;
    } 
    .warning {
        margin-left: 0px;
    }
    .footerline {
        width: 100%;
    }
    .announcementStyle {
        margin-bottom: 130px;
    }
}

@media only screen and (max-width: 460px) {
    .footer {
        background: url('./images/footer_mobile.png');
        margin-top: -57px;
        background-size: cover;
        background-repeat: no-repeat;
    }
}



