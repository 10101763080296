@import 'styles/size.scss';
@import 'styles/colors.scss';

.stack {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  @for $i from 0 through 32 {
    &.spacing-#{$i} {
      gap: $i * $base-size / 2;
    }
  }

  &.alignItems-flex-start {
    align-items: flex-start;
  }

  &.alignItems-flex-end {
    align-items: flex-end;
  }

  &.alignItems-center {
    align-items: center;
  }

  &.alignItems-stretch {
    align-items: stretch;
  }

  &.alignItems-baseline {
    align-items: baseline;
  }

  &.justifyContent-flex-start {
    justify-content: flex-start;
  }

  &.justifyContent-flex-end {
    justify-content: flex-end;
  }

  &.justifyContent-center {
    justify-content: center;
  }

  &.justifyContent-space-between {
    justify-content: space-between;
  }

  &.justifyContent-space-around {
    justify-content: space-around;
  }

  &.justifyContent-space-evenly {
    justify-content: space-evenly;
  }

  &.fullWidth {
    width: 100%;
  }

  &.fullFlex {
    flex: 1;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.nowrap {
    flex-wrap: nowrap;
  }
}
