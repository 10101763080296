.detailCategory {
    font-size: 18px !important;
    font-weight: 400 !important;
    color : #00CDE7 !important;
}

.detailTitle {
    font-size: 32px !important;
    font-weight: 400 !important;
}

.detailPublish {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: grey !important;
    margin-top: 0px !important;
}

.detailImg {
    margin-top: 20px !important;
}

.blogImg {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.blogDetails {
    margin-top: 40px;
    font-size: 16px !important;
    color : #00345A;
}

.blogDetails table {
    border-collapse: collapse;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.blogDetails th {
    border: 1px solid #ddd;
    padding: 8px;
}

.blogDetails td {
    border: 1px solid #ddd;
    padding: 8px;
}

.blogDetails .media {
    margin: 20px auto 20px auto;
    width: 70%;
} 

.blogDetails .image_resized {
    margin: 0 auto 0 auto;
}


.blogDetails .image > img {
    width: 100%;
    min-height: 100%;
}

.promotions-margin {
    margin-top: 104px;
}

blockquote {
    color: #666;
    margin: 0;
    padding-left: 1em;
    border-left: 0.5em #eee solid;
    font-style: italic;
  }  

@media (max-width: 576px) {
    .blogImg {
        display: block;
        height: 260px;
    }
    .blogDetails .media {
        width: 100%;
    }
}

@media (max-width: 575.9px) {
    .blogDetails table td {
        font-size: 12px;
    }
}

@media (max-width: 424.9px) {
    .blogDetails table td {
        font-size: 10px;
    }
}