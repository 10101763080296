@import 'styles/size.scss';

.collapsible {
  width: 100%;
}

.hkidSymbolsImg {
  width: 330px;
  margin-top: $base-size * 1.5;
  margin-bottom: $base-size * 2;
}

@media (max-width: $web-responsive-width) {
  .hkidSymbolsImg {
    width: 100%;
    margin-top: 0px;
  }
}
