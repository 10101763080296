.joinus-margin {
    margin-top: 148px;
}

.joinus {
    display: block;
    width: 100%;
    height: auto;
}

.joinus-title {
    font-size: 56px;
    font-weight: 400;
    background: linear-gradient(to left ,rgba(0, 205, 231, 1), rgba(75, 163, 255, 1));
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.joinus-desc {
    font-size: 16px;
    font-weight: 400;
    color: #00345A;
    margin-top: 20px;
}

.joinus_content {
    margin-top: 100px;
    vertical-align: middle;
}

.our-team {
    font-size: 32px;
    font-size: 400;
    color: #00345A;
}

.out-team-desc {
    color: #00345A;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
}

.opportunity-desc {
    color: #00345A;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    line-height: 15px;
}


.join-linked-area {
    margin-bottom: 85px;
    /* transform: translateY(-1%); */
}
@media (max-width: 576px) {
    .joinus-title {
        font-size: 32px !important;
        padding: 0 10px !important;
    }

    .joinus-desc {
        padding: 0 10px !important;
    }

    .our-team-sm {
        padding: 0px 10px !important;
        margin-top: 60px;
    }
    .join-linked-area {
        padding: 0 10px !important;
        margin-top: -58px;
    }

    .opportunity-desc {
        line-height: 18px;
    }
    .joinright {
        order: 1;
    }
    .joinleft {
        order: 2;
    }
}

.opportunitylist {
    margin-bottom: 80px;
}

@media (min-width: 1200px) {
    .join-linked-area {
        margin-top: -80px;
    }
}

