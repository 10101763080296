@import 'styles/size.scss';
@import 'styles/colors.scss';
@import 'styles/font.scss';

.text {
  margin: 0px;
  padding: 0px;

  font-style: normal;
  font-weight: $font-normal;
  font-size: $body1-font-size;
  line-height: $body1-line-height;

  &.size-hero1 {
    color: $text-heading-color;
    font-size: $hero1-font-size;
    line-height: $hero1-line-height;
  }

  &.size-xh1 {
    color: $text-heading-color;
    font-size: $xh1-font-size;
    line-height: $xh1-line-height;
  }

  &.size-h1 {
    color: $text-heading-color;
    font-size: $h1-font-size;
    line-height: $h1-line-height;
  }

  &.size-h2 {
    color: $text-heading-color;
    font-size: $h2-font-size;
    line-height: $h2-line-height;
  }

  &.size-h3 {
    color: $text-heading-color;
    font-size: $h3-font-size;
    line-height: $h3-line-height;
  }

  &.size-h4 {
    color: $text-heading-color;
    font-size: $h4-font-size;
    line-height: $h4-line-height;
  }

  &.size-title1 {
    color: $text-title-color;
    font-size: $title1-font-size;
    line-height: $title1-line-height;
  }

  &.size-title2 {
    color: $text-title-color;
    font-size: $title2-font-size;
    line-height: $title2-line-height;
  }

  &.size-body1 {
    color: $text-body-color;
    font-size: $body1-font-size;
    line-height: $body1-line-height;
  }

  &.size-body2 {
    color: $text-body-color;
    font-size: $body2-font-size;
    line-height: $body2-line-height;
  }

  &.size-body3 {
    color: $text-body-color;
    font-size: $body3-font-size;
    line-height: $body3-line-height;
  }

  &.weight-normal {
    font-weight: $font-normal;
  }

  &.weight-medium {
    font-weight: $font-medium;
  }

  &.weight-semibold {
    font-weight: $font-semibold;
  }

  &.weight-bold {
    font-weight: $font-bold;
  }

  &.color-heading {
    color: $text-heading-color;
  }

  &.color-title {
    color: $text-title-color;
  }

  &.color-body {
    color: $text-body-color;
  }

  &.color-white {
    color: $text-white-color;
  }

  &.color-grey {
    color: $text-grey-color;
  }

  &.color-inactive {
    color: $text-inactive-color;
  }

  &.color-error {
    color: $text-error-color;
  }

  &.color-tip {
    color: $text-tip-color;
  }

  &.color-link {
    color: $text-link-color;
  }

  &.wrap {
    text-wrap: wrap;
  }

  &.nowrap {
    text-wrap: nowrap;
    white-space:nowrap;
  }

  &.align-left {
    text-align: left;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }

  @media (max-width: $web-responsive-width) {
    &.size-lg-xh1 {
      font-size: $xh1-font-size;
      line-height: $xh1-line-height;
    }

    &.size-lg-h1 {
      font-size: $h1-font-size;
      line-height: $h1-line-height;
    }

    &.size-lg-h2 {
      font-size: $h2-font-size;
      line-height: $h2-line-height;
    }

    &.size-lg-h3 {
      font-size: $h3-font-size;
      line-height: $h3-line-height;
    }

    &.size-lg-h4 {
      font-size: $h4-font-size;
      line-height: $h4-line-height;
    }

    &.size-lg-title1 {
      font-size: $title1-font-size;
      line-height: $title1-line-height;
    }

    &.size-lg-title2 {
      font-size: $title2-font-size;
      line-height: $title2-line-height;
    }

    &.size-lg-body1 {
      font-size: $body1-font-size;
      line-height: $body1-line-height;
    }

    &.size-lg-body2 {
      font-size: $body2-font-size;
      line-height: $body2-line-height;
    }

    &.size-lg-body3 {
      font-size: $body3-font-size;
      line-height: $body3-line-height;
    }
  }
}
