@import 'styles/colors.scss';
@import 'styles/font.scss';

$security-flow-height: 1345px;
$lottie-shadow-range: 982px;

.lottieShadow {
  position: absolute;
  z-index: 50;
  width: $lottie-shadow-range;
  height: $lottie-shadow-range;
  border-radius: $lottie-shadow-range;
  background-image: radial-gradient(50% 50% at 50% 50%,
      #daf4f8 0%,
      rgba(255, 255, 255, 0) 100%);
}

.heroTextGradient {
  text-align: center;
  background: var(--text-gradient,
      linear-gradient(135deg, #00cde7 15.42%, #4ba3ff 87.08%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header {
  // height: 370px;
  background: linear-gradient(180deg, #d3f4f8 0%, rgba(211, 244, 248, 0) 100%);
}

.headerShield {
  margin-top: 207px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerShieldIcon {
  width: 40px;
  height: 50px;
}

.headerShieldTextWrapper {
  @extend .heroTextGradient;
  margin-top: 21px;
  max-width: 328px;
}

.mainContainer {
  position: relative;
  margin-top: 24px;
}

.securityFlowAnimationBoard {
  position: relative;
}

.securityFlowBg {
  display: flex;
}

.securityFlowCyan {
  width: 100%;
  position: absolute;
  top: 0;
  /*  using this svg file to implement the animation that multiple sliders run on track,
    * you can adjust styles by changing css and svg properties.  
  */
  mask-image: url('./images/slider_mask.svg');
}

.securityFlowBgMiddle {
  width: 100%;
  height: 2188px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}

.securityFlowBgGreyItem {
  background-image: url('./images/grey.png');
}

.securityFlowBgCyanItem {
  background-image: url('./images/cyan.png');
}

.flowSliderStart {
  animation: 14.4s slider infinite linear;
}

@keyframes slider {
  0% {
    mask-position: 0 0;
  }

  100% {
    mask-position: 0 2188px; // 2188px is the height of cyan.png
  }
}

.securityFlowContent {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.stage0Placeholder {
  height: 214px;
}

.stageNumber {
  margin-top: -6px;
  margin-right: 24px;
}

.stageDescriptionWrapper {
  width: 297px;
}

.stageDescription {
  margin-bottom: 8px;
}

.stage1Row {
  position: relative;
  height: 465px;
}

.stage1Key {
  position: absolute;
  width: 86px;
  height: 86px;
  left: 50%;
  top: 240px;
  margin-left: -43px;
  padding: 0;
}

.stage1DescriptionCol {
  margin-right: 82px;
  display: flex;
  justify-content: end;
}

.stage2Row {
  height: 591px;
  position: relative;
}

.stage2Key {
  position: absolute;
  width: 86px;
  height: 86px;
  left: 50%;
  top: 320px;
  margin-left: -43px;
  padding: 0;
  z-index: 100;
}

.stage2Description {
  margin-bottom: 16px;
}

.stageDescriptionSub {
  margin-bottom: 0;
}

.inputGroupCol {
  margin-left: 82px;
}

.inputGroupLottie {
  width: 276px;
  height: 128px;
}

.stage2DescriptionCol {
  display: flex;
  margin-left: 82px;
  padding-top: 48px;
}

.encryptionLottieWrapper {
  position: relative;
  margin-right: 94px;
  display: flex;
  justify-content: end;
}

.encryptionLottie {
  height: 255px;
  width: 255px;
  margin-right: 64px;
  z-index: 100;
}

.stage2EncryptionShadow {
  @extend .lottieShadow;
  top: -330px;
  right: -220px;
}

.stage3Row {
  height: 492px;
  position: relative;
}

.stage3DescriptionCol {
  margin-right: 82px;
  display: flex;
  justify-content: end;
}

.stage3Data {
  position: absolute;
  width: 86px;
  height: 86px;
  left: 50%;
  top: 320px;
  margin-left: -43px;
  padding: 0;
  z-index: 100;
}

.databaseColumn {
  margin-left: 82px;
}

.databaseRow {
  justify-content: start;
  margin-top: -16px;
  position: relative;
}

.databaseLottie {
  width: 300px;
  height: 202px;
  z-index: 100;
  position: relative;
}

.stage3DatabaseShadow {
  @extend .lottieShadow;
  top: 50%;
  left: 50%;
  margin-top: -491px;
  margin-left: -491px;
}

.stage4EncryptionContainer {
  margin-right: 94px;
  display: flex;
  justify-content: end;
}

.stage4DescriptionCol {
  display: flex;
  justify-content: start;
  margin-left: 82px;
  padding-top: 90px;
}

.phoneContainer {
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: radial-gradient(50% 50% at 50% 50%,
      #daf4f8 30.5%,
      rgba(255, 255, 255, 0) 100%);
}

.phoneImg {
  display: block;
  width: 210px;
  height: 432px;
  margin-bottom: 104px;
}

.getStartedContainer {
  margin-top: -61px;
}

.bottomShadow {
  height: 298px;
  margin-top: -298px;
  background-image: linear-gradient(180deg,
      rgba(211, 244, 248, 0) 0%,
      #d3f4f8 100%);
}

.bottomGradientText {
  @extend .heroTextGradient;
  max-width: 516px;
  margin-bottom: 38px;
}

@media (max-width: 976px) {
  .headerShield {
    margin-top: 150px;
  }

  .headerShieldTextWrapper {
    margin-top: 16px;
  }

  .securityFlowAnimationBoard {
    margin-left: 16px;
    margin-right: 16px;
    top: 44px;
    height: 1394px; // 1322 + 72
  }

  .mainContainer {
    margin-top: 58px;
  }

  .securityFlowBg {
    display: flex;
    width: 100%;
    height: $security-flow-height;
  }

  .securityFlowBgGreyItem {
    background-image: url('./images/grey_mobile.svg');
  }

  .securityFlowBgCyanItem {
    background-image: url('./images/cyan_mobile.svg');
  }

  .securityFlowBgLeft {
    width: 120px;
    height: $security-flow-height;
  }

  .securityFlowBgMiddle {
    flex: 1;
    height: $security-flow-height;
    background-repeat: no-repeat;
  }

  .securityFlowBgRight {
    width: 120px;
    height: $security-flow-height;
    background-position: -858px 0;
    background-repeat: no-repeat;
  }

  .stage0Placeholder {
    height: 0;
  }

  .stageNumber {
    margin-top: -2px;
    margin-left: 4px;
    margin-right: 12px;
  }

  .stageDescriptionWrapper {
    width: calc(100% - 91px);
  }

  .stage1Row {
    height: 448px;
    flex-direction: column;
  }

  .stage1Key {
    display: none;
  }

  .inputGroupCol {
    margin-left: 0;
    padding-left: 52px;
    padding-top: 30px;
  }

  .inputGroupLottie {
    padding-left: 0;
    margin-left: -8px;
  }

  .stage1DescriptionCol {
    margin-right: 0;
    flex-grow: 0;
    display: flex;
    justify-content: start;
  }

  .stage2Row {
    height: 311px;
    justify-content: space-between;
    flex-direction: column-reverse;
  }

  .stage2Key {
    display: none;
  }

  .stage2DescriptionCol {
    margin-left: 0;
    padding-top: 0;
    flex-grow: 0;
  }

  .encryptionLottieWrapper {
    margin-right: 0;
    height: 140px;
    flex-grow: 0;
    margin-bottom: 16px;
  }

  .encryptionLottie {
    width: 140px;
    height: 140px;
    margin-right: 0;
  }

  .stage2EncryptionShadow {
    display: none;
  }

  .stage3Row {
    height: 315px;
    flex-direction: column;
  }

  .stage3DescriptionCol {
    justify-content: start;
  }

  .stage3Data {
    display: none;
  }

  .databaseColumn {
    display: flex;
    margin-left: 0;
    height: 159px;
    justify-content: end;
  }

  .databaseLottieWrapper {
    display: flex;
  }

  .databaseLottie {
    width: 150px;
    height: 100px;
    margin-right: 20px;
  }

  .databaseRow {
    margin-top: 18px;
    justify-content: end;
  }

  .stage3DatabaseShadow {
    display: none;
  }

  .stage4Row {
    height: 320px;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .stage4DescriptionCol {
    flex-grow: 0;
    margin-left: 0;
    padding-top: 0;
  }

  .stage4EncryptionContainer {
    height: 140px;
    flex-grow: 0;
    margin-bottom: 42px;
  }

  .encryptionLottieStage4 {
    margin: 0;
  }

  .phoneImg {
    width: 140px;
    height: 287px;
    margin-bottom: 201px;
  }

  .bottomShadow {
    height: 513px;
    background: linear-gradient(0deg, #d3f4f8 0%, rgba(211, 244, 248, 0) 100%);
    margin-top: -513px;
  }

  .bottomGradientText {
    max-width: 328px;
    margin-bottom: 21px;
  }
}