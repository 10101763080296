.landingFooter {
    width: 100%;
    height: 256px;
    background: url('../images/landingfooter.svg');
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1920px;
}

.onesubFooter {
    margin-left: 40px;
    margin-top: 50px;
}

.oneSub {
    font-size: 36px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 10px;
    letter-spacing: 2px;
}

.landingAddress {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.landingLinks {
    margin-top: 100px;
    float: right;
    margin-right: 10px;
}

.landingPolicy {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-align: end;
    margin-right: 40px;
}

.landingSocailLinks {
    margin-right: 20px;
    margin-top: 20px;
    display: flex;
}

@media (max-width: 568px) {
    .landingFooter {
        width: 100%;
        height: 367px;
        background: url('../images/landingfootermobile.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .landingLinks {
        margin-top: 45px;
        float: left;
        margin-left: 25px;
    }
    .onesubFooter {
        margin-left: 25px;
    }
    .landingPolicy {
        text-align:start;
    }
}