.signUp {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 4px 4px 17px 6px rgb(0 0 0 / 15%);
    padding: 15px 15px 1px 15px;
}

.signUpheader {
    font-size: 22px;
    font-weight: 400;
    color:#00345A;
}

.signUp-text {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #99B3C6;
}

.gKeeper-text {
    line-height: 20px;
}

.submitno {
    display: flex;
}

.inputArea {
    height: 54px;
    border: 1px solid #99B3C6;
    border-radius: 5px;
    padding: 8px;
    margin-left: 12px;
}

.phno {
    width: 265px;
    height: 44px;
    border: 1px solid #99B3C6;
    border-radius: 5px;
    display: flex;
    padding: 8px;
}
.countrycode {
    font-size :18px;
    font-weight: 400;
    margin-left: 4px;
    color: #00345A;
}

.numberInput {
    border: none;
    outline: none;
    color:#00345A;
    font-size: 18px;
    font-weight: 400;
    margin-left: 14px;
    width: 70%;
}

::placeholder {
    color: #99B3C6;
  }

.submit {
    height: 44px;
    background: linear-gradient(to left ,rgba(0, 52, 90, 1),rgba(51, 103, 141, 1));
    color: white;
    border-radius: 44px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 44px;
    cursor: pointer;
    margin: auto;
}

.submitError {
    font-size: 13px;
    color: #00345A;
} 

@media (max-width: 576px) {
    .submit {
        margin-top: 8px;
        width: 95%;
    }
    .inputArea {
        width: 95%;
    }
}
