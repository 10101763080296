.thankubg {
    background: url('../images/thankubg.svg') ;
    width: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 287px;
}

.waitingListTick {
    margin-top: -85px;
}

.success {
    width: 103px;
    height: 103px;
}

.thankuHeading {
    font-size: 40px;
    font-weight: 600;
    color: #004171;
}

.thankuDetail {
    width: 88%;
    justify-content: center;
    display: block;
    padding: 15px 0px 24px 16px;
}

.descHeading {
    font-size: 26px;
    font-weight: 600;
    color: #004171;
    text-align: start;
    margin-bottom: 17px;
    margin-left: -5px; 
    margin-top: 15px;   
}

.thankuDetail p {
    font-size: 16px;
    font-weight: 400;
    color: #004171;
    text-align: justify !important;
}

.clipinput {
    position: relative;
}

.clipboard {
    width: 100%;
    height: 42px;
    color: #004171;
    padding: 9px 20px;
    border: 1px solid #99B3C6;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 16px;
}

.copy {
    position: absolute;
    right: 12px;
    top: 14px;
    cursor: pointer;
}

.thankuwaitingList {
    margin-top: 38px;
    background: linear-gradient(87.92deg, #6489FF -33%, #4BA3FF 21.36%, #00CDE7 76.22%, #DFFBFF 140.03%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
   text-align: center;
   width: 285px;
   height: 38px;
   color: #fff;
   line-height: 38px;
   cursor: pointer;
}

.linked {
    position: fixed;
    bottom: 30px;
    right: 47%;
    background-color:#000000;
    display: flex;
    align-items: center;
    padding: 7px 13px;
    height: 30px;
    border-radius: 50px;

}

.link-copied {
    color: white;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
}

.footerspace {
    margin-top : 97px;
    position: relative;
}


@media (max-width: 568px) {
    .thankubg {
    background: url('../images/thankumobile.svg') ;
    height: 211px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    }
    .waitingListTick {
        margin-top: -75px;
    }
    .thankuHeading  {
        font-size: 26px !important;
        margin-top: 45px;
        padding: 0 20px;
    }
    .descHeading {
        margin-top: 13px;
        font-size: 16px;
        text-align: center !important;
    }
    .thankuDetail {
        padding: 0;
        width: 100% !important;
    }
    .thankuDetail p {
        margin-top: 32px;
        font-size: 12px;
        text-align: justify !important;
        margin-right: 7px;
    }
    .clipboard {
        padding: 9px 5px;
        font-size: 16px;
    }
    .linked { 
        right: 40%;
    }
}

.t-condition {
    margin-top: 20px;
}

.thankuTerm {
    font-size: 12px;
    font-weight: 400;
    color: #99B3C6;
    margin-bottom: 0;
}

.termC {
    margin-top: 20px;
}

.termC h3 {
    font-size: 12px;
    font-weight: 600;
    color:  #00345A;
}

.termC p {
    font-size: 12px;
    font-weight: 400;
    color:  #99B3C6;
}