.landingNav {
    height: 88px;
    background: transparent;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    display: flex;
    max-width: 1920px; 
}

.scrolled {
    background-color: #FAFAFA !important;
}

.scrolledlogo {
    color: #00345A !important;
}

.landingLogoDiv {
    margin-top: 15px;
}

.landingLogo {
    font-size: 36px;
    font-weight: 400;
    color: #fff;
    margin-left: 30px;
    letter-spacing: 2px;
    margin-bottom: 0;
}

.logoDesc {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    margin-left: 30px;
    line-height: 17px;
    letter-spacing: 10px;
    margin-top: -7px;
}

.comingSoon {
    height: 46px;
    background-color: #99B3C6;
    border-radius: 40px;
    margin-right: 40px;
}

.coming {
    font-size: 18px;
    line-height: 46px;
    font-weight: 400;
    color:#fff;

}

@media (max-width: 480px) {
    .landingIcons {
        display: none !important;
    }

    .landingLogo {
        margin-left: 10px;
        font-size: 28px;
    }

    .logoDesc {
        margin-left: 13px;
        font-size: 10px;
    }

    .comingSoon {
        margin-right: 10px;
    }

    .comingSoon {
        width: 124px;
        height: 32px;
    }

    .coming {
        line-height: 32px;
        font-size: 12px;
    }
}