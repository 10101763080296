@import 'styles/size.scss';

.container {
  padding-top: 88px;

  display: flex;
  flex-direction: column;
  position: relative;

  background: url('./images/blue_background.svg'), #fafafa;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;

  // 100px footer -margin and 120px bottom
  padding-bottom: 100px + 120px;
}

.card {
  margin: 0 auto;
  max-width: 997px;

  padding: $base-size * 5;
  border-radius: $base-size * 2.5;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;

  background-color: #fff;
}

.narrowerCard {
  max-width: 794px;
}

.innerCard {
  border-radius: $base-radius * 2.5;
  background: #f3feff;

  margin-top: $base-size * 5;
  margin-bottom: $base-size * 2.5;

  padding-top: $base-radius * 3.75;
  padding-bottom: $base-radius * 2.5;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .imgSerice {
    width: 264px;
  }
}

.cellContainer {
  margin-top: $base-size * 3.75;

  display: flex;
  flex-direction: row;
  gap: $base-size * 1.25;

  .cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $base-size * 1.5;

    text-align: center;
    max-width: 200px;

    .cellTextContainer {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .icon {
      width: 85px;
      object-fit: contain;
    }
  }
}

.workDayText {
  background: linear-gradient(135deg, #00cde7 15.42%, #4ba3ff 87.08%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.exploreTitle {
  background: linear-gradient(135deg, #00cde7 15.42%, #4ba3ff 87.08%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.maxWidthLimit {
  max-width: 792px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #edf1f2;

  margin: $base-size * 5 0;
}

.qrcode {
  margin-bottom: $base-size * 2.5;

  canvas {
    width: 140px;
    height: 140px;
  }
}

.progressSteps {
  position: relative;

  max-width: 580px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: $base-size * 5;

  > div {
    z-index: 1;
  }

  .line {
    position: absolute;
    z-index: 0;

    top: 15px;
    left: 10%;
    right: 10%;

    height: 3px;
    background-color: #13db70;
  }

  .nodeNum {
    width: 30px;
    height: 30px;
    border-radius: 40px;
    border: 2px solid #13db70;

    color: #13db70;
    background: #fff;
    line-height: 26px;
  }
}

.getAppButton {
  max-width: 320px;
  padding-left: 46px;
  padding-right: 46px;

  .withMarginRight {
    margin-left: $base-size;
  }

  &.floatButton {
    display: none;

    position: sticky;
    bottom: $base-size * 5;
  }
}

.gap {
  margin-top: $base-size * 10;
}

@media (max-width: $web-responsive-width) {
  .card {
    padding: $base-size * 2;
    margin: 0 $base-size * 2;
  }

  .innerCard {
    .imgSerice {
      width: 80%;
    }
  }

  .cellContainer {
    width: 100%;
    margin-top: $base-size * 2.5;
    margin-bottom: $base-size * 2.5;

    flex-direction: column;
    gap: $base-size * 2.5;

    .cell {
      width: 100%;
      max-width: 100%;

      flex-direction: row;
      align-items: center;
      gap: $base-size * 2.5;

      .cellTextContainer {
        flex: 1;
        flex-direction: column;
        text-align: left;
      }

      .icon {
        width: 65px;
      }
    }
  }

  .progressSteps {
    width: 100%;
    gap: 0;

    > div {
      flex: 1;
    }
  }

  .qrcode {
    display: none;
  }

  .getAppButton {
    width: 100%;

    &.floatButton {
      display: flex;
    }
  }

  .gap {
    margin-top: $base-size * 5;
  }
}
