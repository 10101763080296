.term {
    margin-top: 128px;
    margin-bottom: 100px;
}

.term h2 {
    font-size: 24px;
    font-weight: 400;
    color : #00345A;
}

.head {
    font-size: 32px !important;
    font-weight: 400;
    color : #00345A;
}

.term p {
    margin-top: 20px;
    color : #00345A;
}

.term h5 {
    font-size: 18px;
    font-weight: 400;
    color : #00345A;
    margin-top: 20px;
} 

.termDeleteAccountTips {
  /* ".term p" overwrite */
  margin-top: 20px !important;
  margin-bottom: 80px !important;

  color: #8e9599 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.termDeleteAccountTips > a {
  color: #4ba3ff;
  text-decoration: none;
}
