.get_started {
    width: 100%;
    min-height: 510px;
    background: url('./images/get_started_banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding-top: 1px;
}

.start_heading {
    color: #fff;
    font-size: 40px;
    font-weight: 400;
    margin-top: 100px;
}

.signup-started {
    margin-top: 40px;
}

@media (min-width: 1024px) and (max-width: 1240x) { 
    .get_started {
    min-height: 500px !important;
    }
 }


 @media (min-width: 460px) and (max-width: 575px) { 
    .get_started {
        width: 100%;
        min-height: 550px !important;
        background: url('./images/sign_up_mobile.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .start_heading {
        margin-top: 60px;
    }
    .signup-started {
        margin-top: 40px !important;
    }
}

  @media (max-width: 460px) { 
    .get_started {
        width: 100%;
        min-height: 482px;
        background: url('./images/sign_up_mobile.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .start_heading {
        margin-top: 60px;
    }
    .signup-started {
        margin-top: 40px !important;
}

  }

  @media (min-width: 375px) and (max-width: 425px) { 
    .signup-started {
        margin-top: 40px !important;
    }
    .start_heading {
        margin-top: 60px;
    }
    .get_started {
        min-height: 518px !important;
    }
  }

  @media (min-width: 320px) and (max-width: 374px) { 
    .signup-started {
        margin-top: 40px !important;
}
  }