@import 'styles/size.scss';

.modalContent {
  padding: $base-size * 5 $base-size * 2.5;
  border-radius: $base-radius * 2.5;
}

.modalDialog {
  padding: $base-size * 2.5;
}
