.aboutUs-banner {
    background: url('./images/about_us_banner.png');
    width: 100%;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 560px !important;
}

/* @media only screen and (min-width: 576px) {
    .about_us_midBanner {
        min-height: 1258px !important;
    }
} */

@media only screen and (min-width: 768px) {
    .aboutUs-banner {
        min-height: 460px !important;
        background-position: bottom;
    }
    .about-content {
        margin-top: 92px !important;
    }
    /* .paragraphs {
        margin-top: 25px !important;
    } */
}

@media only screen and (min-width: 1024px) {
    .aboutUs-banner {
        min-height: 438px !important;
        background-position: bottom;
    }
    .about-content {
        margin-top: 130px !important;
    }
    /* .paragraphs {
        margin-top: 60px !important;
    } */
}

@media (min-width: 1220px) and (max-width: 1439.90px) { 
    .aboutUs-banner {
        min-height: 592px !important;
        background-position: bottom;
    }
 }

@media only screen and (min-width: 1440px) {
    .aboutUs-banner {
        min-height: 647px !important;
        background-position: bottom;
    }
    .about-content p {
        width: 100%;
        margin-top: 4% !important;
    }
    .about-content {
        margin-top: 130px !important;
        min-height: 448px;
    }
}


.about-content {
    margin-top: 130px;
}

.about-content h1 {
    color: #fff;
    font-size: 40px;
    font-weight: 400;
}

.about-content p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
}

.vision {
    margin-top: 100px;
}

.about_1 {
    display: block;
    width: 100%;
    height: auto;
}

.our-vision h2{
    margin-top: 50px;
    padding-bottom: 20px;
    font-size: 32px !important;
    font-weight: 400;
    color: #00345A;

}

.our-vision p{
    color: #00345A;
}


.about_us_midBanner {
    background: url('./images/about_us_midBanner.png');
    width: 100%;
    background-size: cover ;
    margin-top: 100px;
    padding: 130px 0;
}

.our_values h2 {
    font-size: 32px;
    font-weight: 400;
    color: #00345A;
}

.our_values_para {
    font-size: 16px;
    font-weight: 400;
    color: #00345A;
    margin-top: 20px;
}

.paragraphs {
    margin-top: 60px;
}

.paragraphs2 {
    margin-top: 40px;
}

.para-heading {
    font-size: 18px;
    font-weight: 400;
    color: #00345A;
}

.para-desc {
    font-size: 16px;
    font-weight: 400;
    color: #00345A;
    margin-top: 10px;
}

.grantit1 {
    display: block;
    width: 100%;
    height: auto;
}

.aboutUs-bottom {
    margin: 90px 0 165px 0;
}

.logo_vertical {
    display: block;
    max-width: 285px;
    max-height: 161px;
    width: 100%;
    height: auto;

}

.hero h2 {
    font-size: 32px;
    font-weight: 400;
    color: #00345A;
}

.hero p {
    font-size: 16px;
    font-weight: 400;
    color: #00345A;
}

@media (max-width: 768px) {
    .vision {
        margin-top: 60px;
    }
    .our-vision h2  {
        margin-top: 40px;
    }
    .about_us_midBanner {
        margin-top: 60px;
    }
    .aboutUs-bottom {
        margin: 62px 0 100px 0;
    }
    .hero {
        margin-top: 43px;
      }
}

@media only screen and (max-width: 460px) {
    .aboutUs-banner {
        background: url('./images/about_us_mobile.png');
        width: 100%;
        min-height: 820px !important;
        background-size: 100% 100% ;
    }
    .about-content {
        margin-top: 97px;
    }
    .about-content p {
        text-align: center !important;
    }
    .about-content h1 {
        text-align: center;
    }

    .about_us_midBanner {
        background: url('./images/about_us_midmobile.png');
        width: 100%;
        min-height: 1008px;
        background-size: 100% 100% ;
        padding: 50px 7px 0 7px;
    }

    .about1Img {
        padding: 0 !important;
    }
    .about_1 {
        width: 90%;
    }

    .about-content p {
        padding: 0 1px;
    }
    .grantit1 {
        padding-top: 0 !important;
    }
    .para-heading {
        margin-top: 30px;
    }
    .paragraphs2 {
        margin-top: 0px;
    }
    .paragraphs {
        margin-top: 0;
    }
}

@media (min-width: 1500px) and (max-width: 1599.98px) { 
    .about_us_midBanner {
        padding: 143px 0;
    }
 }

 @media (min-width: 1600px) and (max-width: 1799.98px) {
    .about_us_midBanner {
        padding: 169px 0;
    }
    .para-heading {
        font-size:24px !important;
    }
    .para-desc {
        font-size: 18px !important;
    }

}

@media (min-width: 1800px) and (max-width: 1999.98px) {
    .our_values h2 {
        font-size: 42px !important;
    }
    .our_values_para {
        font-size: 20px !important;
    }
    .about_us_midBanner {
        padding : 181px 0;
    }
    .para-heading {
        font-size:30px !important;
    }
    .para-desc {
        font-size: 22px !important;
    }

}

@media only screen and (min-width: 2000px) {
    .about_us_midBanner {
        padding : 192px 0;
    }
}

@media only screen and (min-width: 2560px) {
    .about_us_midBanner {
        padding : 257px 0;
    }
}