.modal-title {
    font-size: 32px;
    font-weight: 400;
    color: #00345A;
    padding-right: 20px;
}

.modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
}

.modal-header {
    border-bottom: 0px !important;
}

.model-para {
    font-size: 16px;
    font-weight: 400;
    color: #00345A;
}

.modal-footer {
    background: url('./images/thankufooter.png');
    width: 100%;
    min-height: 135px;
    border-top: 0px !important;
    background-repeat: no-repeat;
    background-position: top;
    display: inline-block;
    background-size: cover;
}

.modalfooterDiv {
    margin-top: 47px;
    margin-bottom: 20px;
    display: flex;
}

.modal-dialog {
    max-width: 595px ;
}

.modelBody {
    margin-right: 30px;
}

.model-download-app > canvas {
    width: 120px;
    height: 120px;
}

@media (max-width: 768px) {
    .buttonsection {
        justify-content: center !important;
        order: 1 !important;
        margin-top: 30px;
    }
    .logosection {
        justify-content: center !important;
        order: 2 !important
    }

    .logosection> img {
        margin-top: 40px;
    } 
    .modalfooterDiv {
        display: flex !important;
        flex-direction: column !important;
    }

    .modal-title {
        padding-right: 0px;
    }
    

    .modal-footer {
        background: url('./images/thankumobile.png');
        width: 100%;
        min-height: 250px;
        border-top: 0px !important;
        background-repeat: no-repeat;
        background-position: top;
        display: inline-block;
        background-size: cover;
    }
}
