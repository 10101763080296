.offerArea {
    background: #FFFFFF;
    min-height: 56px;
    height: auto;
    overflow-y: scroll !important;
    position: fixed;
    bottom: 0;
    z-index: 999;
}

.annoucementStyle {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    margin-left: 7px;
    text-align: left;
}

.alertImg {
    width: 20px;
    height: 20px;
}

.alertCol {
    justify-content: space-between;
    align-items: flex-start;

}

.alertDesc {
    font-weight: 450;
    font-size: 14px;
    color: #00345A;
    width: 100%;
    margin-left: 8px;
    word-wrap: break-word;
}

.closeButton {
    display: 'block';
    margin-left: auto;
}

.alertDesc p {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

@media (min-width: 768px) {
    .offerArea{
        overflow-y: visible !important;
    }
}

@media (max-width: 576px) {
    .alertCol {
        display: block !important;
        justify-content: none !important;
        align-items: none !important;
    }

    .alertDate {
        margin-left: 30px;
       text-align: left !important;
    } 

    .offerArea  {
      padding: 5px 15px 0 7px !important;
    }
    .closeButton {
        height: 20px;
        width: 20px;
    }
}

@media (max-width: 460px) {
    .offerArea {
        min-height: 40px;
    }
}
