.cashLoan {
    background: url('./images/cashLoanBannerCnV1.png') ;
    width: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 630px;
   
}

.cashLoanrDesc {
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    display: flex;
    color: #00345A;

}

.cashloanHeading {
    font-weight: 500;
    font-size: 18px;
    color: #00CDE7;
    line-height: 24px;
}

.cashloanDetail {
    max-width: max-content;
    color: #00345A;
    text-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.cashloanImg {
    width: 590px;
    height: auto;
    margin-top: 140px;

}

.cashLoanLeft {
    margin-top: 50px;
}

.figureDisc {
    font-size: 12px;
    color: #99B3C6;
    margin-top : 20px; 
    text-align: center;
}

.specialLoanCard {
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
    margin: 10px;
    padding-top: 20px;
    cursor: pointer;
}

.specialLoanTitle {
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    color: #354147;
    margin-top: 22px;
}

.specialLoanDesc {
    font-size: 16px;
    font-weight: 450;
    text-align: center;
    color: #8E9599;
} 

.specialLoanDisclaimer {
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: #99B3C6;
}

.creditRating {
    margin-top: 120px;

}

.reloanPhoneImg {
    width: 313px;
    max-width: 100vw;
}

.reloanPhoneImgContainer {
    margin-top: 60px;
    margin-bottom: 120px;
    width: 678px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tuFreeLoan {
    width: 488px;
    height: auto;
    margin-top: 50px;
}

.rowSpecialLoan {
    text-align: center !important;
}

.cashLoanReq {
    color: #99B3C6;
    font-size: 14px;
    font-weight: 450;
}

.loanApplicationProcess {
    margin-top: 120px;
    background: url('./images/applicationProcessBanner.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 725px;
}

.storeLinks {
    margin-top: 40px;
}

.appStore {
    width: 135px;
    cursor: pointer;
}

.googlePlay {
    margin-left: 10px;
    width: 135px;
    cursor: pointer;
}

@media (min-width: 576.1px) and (max-width: 991.9px) {
    .tuFreeLoan {
        width: 100%;
    }
    .cashLoanLeft {
        order: 2;
    }

    .cashLoanRight {
        order: 1;
    }
}


@media (max-width: 576px) {

    .cashLoan {
        background: url('./images/cashLoanBannerMobileCnV1.png') ;
        width: 100%;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        height: 680px;
    }

    .cashloanHeading {
        margin-top: 16px;
        text-align: left !important;
    }
    .cashLoanrDesc {
        font-size: 32px;
    }

    .cashloanImg {
        margin-top: 40px;
        width: 340px;
    }

    .tu-loan-area {
        margin-top: 90px !important;
    }

    .figureDisc {
        text-align: center;
    }

    .tuFreeLoan {
        width: 100%;
    }

    .specialLoanDisclaimer {
        text-align: left;
    }

    .coin-area {
        padding: 0 60px !important;
    }

    #loanGray{
        margin-top: 20px !important;
    }

    .cashLoanLeft {
        order: 2;
    }

    .cashLoanRight {
        order: 1;
    }

    .freeLoanSteps {
        margin-top: 80px !important;
    }

    .freeLoan-good {
        margin-top: 80px;
    }

    .tuLoanImg {
        justify-content: end;
        padding: 0 !important;
    }

    .aiExpArea {
        margin-top: 20px;
    }

    .aiExpAreaMob {
        display: block !important;
    }
    
    .AiExpImg {
       margin-left: -60px;
    }

    .creditModelImg {
        margin-top: 20px;
        padding: 0px 8px 0px 8px;
    }

    .specialLoans {
        margin-top: 100px !important;
    }

    .AiExpDetailHead1 {
        justify-content: center !important;
        text-align: center !important;
    }

    .AiExpDeatlPara1 {
        justify-content: center !important;
        text-align: center !important;
    }
}

.aiExpAreaMob {
    display: none;
}

.tu-loan-area {
    margin-top: 140px;
}

.digits .approve {
    font-size: 16px !important;
    font-weight: 400;
    color: #00345A;
}

.approve {
    color: #004171;
}

.digit {
    margin-top: -22px;
    font-size :56px;
    font-weight: 400;
    background: linear-gradient(to left ,rgba(0, 205, 231, 1), rgba(75, 163, 255, 1));
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.coin-area {
    margin-top: 91px;
}

.coin-img {
    display: block;
    width: 100%;
    height: auto;
}

.freeLoanSteps {
    margin-top: 129px;
}

.cashStepArea {
    margin-top: 30px;
}

.freeLoan-good {
    font-size: 40px;
    line-height: 44px;
    font-weight: 500;
    color: #00345A;
    text-align: center;
}

.cashLoan-step{
    font-size: 40px;
    font-weight: 500;
    color: #99B3C6;
    opacity: 0.5;
}

.cashLoan-desc {
    color: #00345A;
    font-size: 22px;
    font-weight: 500;
}

.cashLoan-detail {
    color: #00345A;
    font-size: 16px;
    font-weight: 400;
}

.tuLoanImg {
    justify-content: center;
}

.specialLoans {
    margin-top: 170px;
}

.AiExpHead {
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    color: #00345A;
}

.AiExpDesc {
    font-weight: 450;
    font-size: 16px;
    align-items: center;
    text-align: center;
    color: #00345A;
}

.AiExpDetailHead1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #00345A;
}

.AiExpDeatlPara1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #00345A;
}

.AiExpImg {
    width: 100%;
    height: auto;
}

.creditModelImg {
    margin-top: 40px;
    width: 100%;
    height: auto;
}

.appProcessImg {
    margin-top: -330px;
}

.applicationHead {
    margin-top: 140px;
}

.downloadCashPage {
    margin-top: 30px;
}

.applicationProcessImg {
    width: 100%;
    height: auto;
}

.cashHowWork {
    margin-top: 100px;
    color: #00345A;
    max-width: 1020px;
}

.appProcessImg {
    margin-top: -360px !important;
}

@media (max-width: 460px) {
    .loanApplicationProcess {
        background: url('./images/applicationProcessBannerMobile.svg') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: bottom !important;
        height: 1050px;
        margin-top: 35px !important;
    }

    .appProcessImg {
        margin-top: -150px !important;
    }

    .downloadCashPage {
        margin-top: 0px !important;
    }

    .applicationHead {
        margin-top: 90px !important;
    }

    .cashHowWork {
        margin-top: 11px !important;
        color: #00345A;
    }
    
}

@media only screen and (min-width: 460.1px) and (max-width: 575.9px) {
    .loanApplicationProcess {
        background: url('./images/applicationProcessBannerMobile.svg') !important;
        width: 100%;
        background-position: bottom !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        height: 1044px;
        margin-top: 35px !important;
    }
    .applicationHead {
        margin-top: 60px !important;
    }
    .cashHowWork {
        margin-top: 31px !important;
    }
}

@media only screen and (max-width: 767.9px) and (min-width: 576px) {
    .cashLoan {
        background: url('./images/cashLoanBannerMobileCnV1.png') ;
        width: 100%;
        background-position: bottom !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        height: 780px;
    }
    .loanApplicationProcess {
        height: 1250px;
    }
    .cashloanHeading {
        margin-top: 0px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 991.9px) {
    .loanApplicationProcess {
        height: 1250px;
    }
}

@media only screen and (min-width: 1660px) {
    .cashLoan {
        height: 680px;
    }
}

@media only screen and (min-width: 1760px) {
    .cashLoan {
        height: 730px;
    }
}

@media only screen and (min-width: 1920px) {
    .cashLoan {
        height: 770px;
    }
}

@media only screen and (max-width: 1650px) and (min-width: 1500px) {
    .loanApplicationProcess {
        height: 850px !important;
    }
}

@media only screen and (min-width: 1750px) {
    .loanApplicationProcess {
        height: 900px !important;
    }
}

@media only screen and (min-width: 1860px) {
    .loanApplicationProcess {
        height: 930px !important;
    }
}
/* @media only screen and (max-width: 1023.90px) and (min-width: 768px) {
    .appProcessImg {
        margin-top: -200px !important;
    }

    .loanApplicationProcess {
        height: 650px !important;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 1024px) {
    .appProcessImg {
        margin-top: -250px !important;
    }
}



@media only screen and (max-width: 1800px) and (min-width: 1650.10px) {
    .loanApplicationProcess {
        height: 880px !important;
    }

    .applicationHead {
        margin-top: 260px !important;
    }
}

@media only screen and (max-width: 2000px) and (min-width: 1800.10px) {
    .loanApplicationProcess {
        height: 950px !important;
    }

    .applicationHead {
        margin-top: 260px !important;
    }
}

@media (min-width: 2000.10px) {
    .loanApplicationProcess {
        height: 950px !important;
    }

    .applicationHead {
        margin-top: 260px !important;
    }
} */