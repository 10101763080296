@import 'styles/size.scss';

.editIcon {
  padding: 0px $base-size * 0.625;

  height: 20px;
  margin-top: -2px;

  user-select: none;
  cursor: pointer;
  transition: linear 0.5s all;

  &:active {
    opacity: 0.5;
  }
}

.submitButton {
  min-width: 264px;
}

@media (max-width: $web-responsive-width) {
  :global(.phone-verication-alert-hide) {
    display: none;
  }
}
