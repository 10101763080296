.home-parent {
    background-color: #FAFAFA;
}
.home {
    background: url('./images/homebanner.png') ;
    width: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 564px !important;
}

.sign-up-responsive {
    margin: 10px 0 140px 0;
    
}

.homeLeftLimitWidth {
    max-width: 540px;
}

.homeRight {
    position: relative;
}

.HomepageBannerImage {
     width: 100%;
     height: auto;
}

.storeLinks {
    margin-top: 40px;
}

.appStore {
    width: 135px;
    cursor: pointer;
}

.googlePlay {
    margin-left: 10px;
    width: 135px;
    cursor: pointer;
}

.cardGrantit {
    background: url('./images/cardGrantit.png');
    background-position: right;
    background-repeat: no-repeat;
    min-height: 563px;
    height: auto;
}

.leftCardGrantit {
    background: #D3F4F8;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 25px;
    height: 305px;
    cursor: pointer;
}

.cardLogoGrantit {
    margin-left: -10px;
    margin-top: -80px;
    width: 180px;
}

.arrow {
    float: right;
}

.cardHeading {
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;  
    color: #00345A;
    text-align: center;
 
 }
 
 .cardDesc {
     font-weight: 450;
     font-size: 16px;
     color: #00345A;
     margin-top: 20px;
     text-align: center;
 }

.leftCardMargin {
    margin-left: 19px;
    background: #6489FF !important;
}

.cardRowGrantit {
    margin-top: 80px ;
    padding: 5px;
}

.leftCardHeading {
    margin-top: 50px;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #00345A;
}

.leftCardPara {
    font-weight: 450;  
    font-size: 16px;
    line-height: 24px;  
    color: #00345A;
    margin-top: 10px;
}

.rightCardHeading {
    margin-top: 50px;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #FFFFFF;
}

.rightCardPara {
    font-weight: 450;  
    font-size: 16px;
    line-height: 24px;  
    color: #FFFFFF;
    margin-top: 10px;
}

.homeScreen {
    display: block;
    width: 488px;
}

.homeScreenStyle {
    margin-left : 200px;
}

.brandAward {
    display: inline;
}

.webLogos {
    display: block;
}

.mobileLogos {
    display: none;
}

@media only screen and (max-width: 992px) and (min-width: 769px) {
    .leftCardHeading {
        margin-top: 10px;
    }
    .rightCardHeading {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 576px) {
    .homeRight {
        text-align: center;
    }
    .HomepageBannerImage {
        width: 75%;
    }
}

@media only screen and (max-width: 768px) and (min-width: 460px) {
    .cardRowGrantit {
        justify-content: center !important;
        padding: 0px;
    }

    .cardLeftMobile {
        padding: 0 10px;
    }

    .home {
        width: 100%;
        background: url('./images/home_banner_mobile.png');
        background-repeat: no-repeat;
        height: 600px !important;
        background-size: cover;
        background-position: bottom !important;
    }

    .homeHeading {
        text-align: left;
        font-size: 32px !important; 
        line-height: 44px !important;
    }

    .home-desc {
        text-align: left;
    }

    .HomepageBannerImage {
        margin-top: 0px !important;
    }

    .bannerImageCn {
        margin-top: 30px !important;
    }

    .homeCardArea {
        margin-top: 100px !important;
    }

    .home-container {
        margin: 87px 0 0 0 !important;
    }

    .mobileSignup {
        padding: 0 10px !important;
    }

    .mobileContent {
        margin-top: 50px !important;
    }

    .home-content p {
        margin-top: 0px !important;
    }

    .homeScreenStyle {
        margin-left: 0px;
    }

    .homeScreen {
        width: 380px;
    }

    .marginMobile {
        margin-left: 6px;
    }

    .grantitVideo {
        min-height: 194px;
    }
    .videoSection {
        margin-top: 40px !important;
    }
    .customer-care {
        background: url('./images/caresolMobile.png') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        min-height: 747px !important;
        padding: 117px 0 0 0 !important;
    }
    .award {
        margin-left: 0 !important;
        margin-top: 30px;
    }
    .awardYear {
        height: 160px;
    }
    .brandAward {
        margin-left: 0px;
    }
    .grantitOption {
        justify-content: center;
    }
    .cardHeading {
        font-size: 32px;
        margin-top: 130px;
        text-align: center !important;
    }

    .cardDesc {
        text-align: center !important;
        padding: 0 7px;
    }
    
    .cardLogoGrantit {
        margin-left: -10px;
        margin-top: -40px;
        width: 108px;
    }

    .cardImg {
        margin-right: -12px;
    }

    .awardLeftArea {
        display: block !important;
    }

    .awardDesc {
        text-align: center !important;
    }

    .cardGrantit {
        background-position: bottom !important;
        min-height: 700px !important;
        height: auto !important;
    }

    .cardRowGrantit {
        justify-content: center !important;
    }

    .leftCardMargin {
        margin-top: 56px !important;
        margin-left: 0 !important;
    }

    .leftCardGrantit {
        width: 85%;
        height: 266px;
    }

    .linkMob {
        justify-content: center !important;
        text-align: center !important;
    }

    .cardRowGrantit {
        margin-top: 30px !important;
    }
}

@media only screen and (max-width: 460px) {
    .home {
        width: 100%;
        background: url('./images/home_banner_mobile.png');
        background-repeat: no-repeat;
        height: 600px !important;
        background-size: cover;
        background-position: bottom !important;
    }

    .homeHeading {
        text-align: left;
        font-size: 32px !important; 
        line-height: 44px !important;
    }

    .home-desc {
        text-align: left;
    }

    .HomepageBannerImage {
        margin-top: 0px !important;
    }

    .bannerImageCn {
        margin-top: 30px !important;
    }

    .homeCardArea {
        margin-top: 100px !important;
    }

    .home-container {
        margin: 87px 0 0 0 !important;
    }

    .mobileSignup {
        padding: 0 10px !important;
    }

    .mobileContent {
        margin-top: 50px !important;
    }

    .home-content p {
        margin-top: 0px !important;
    }

    .homeScreenStyle {
        margin-left: 0px;
    }

    .homeScreen {
        width: 380px;
    }

    .marginMobile {
        margin-left: 6px;
    }

    .grantitVideo {
        min-height: 194px;
    }
    .videoSection {
        margin-top: 40px !important;
    }
    .customer-care {
        background: url('./images/caresolMobile.png') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        min-height: 747px !important;
        padding: 117px 0 0 0 !important;
    }
    .award {
        margin-left: 0 !important;
        margin-top: 30px;
    }
    .awardYear {
        height: 160px;
    }
    .brandAward {
        width: 45%;
        margin-left: 0px;
    }
    .grantitOption {
        justify-content: center;
    }
    .cardHeading {
        font-size: 32px;
        margin-top: 30px;
        text-align: center !important;
    }

    .cardDesc {
        text-align: center !important;
        padding: 0 7px;
    }
    
    .cardLogoGrantit {
        margin-left: -10px;
        margin-top: -40px;
        width: 108px;
    }

    .cardImg {
        margin-right: -12px;
    }

    .awardLeftArea {
        display: block !important;
    }

    .awardDesc {
        text-align: center !important;
    }

    .cardGrantit {
        background-position: bottom !important;
        min-height: 700px !important;
        height: auto !important;
    }

    .cardRowGrantit {
        justify-content: center !important;
    }

    .leftCardMargin {
        margin-top: 56px !important;
        margin-left: 0 !important;
    }

    .leftCardGrantit {
        width: 85%;
        height: 266px;
    }

    .linkMob {
        justify-content: center !important;
        text-align: center !important;
    }

    .cardRowGrantit {
        margin-top: 30px !important;
    }
 }

 @media only screen and (max-width: 425px) {
    .customer-care {
        min-height: 693px !important;
    }
}

 @media only screen and (min-width: 426px) {
    .home-content h3 {
        font-size: 28px !important;
    }

    .signup {
        padding-left: 0px !important;
    }

 }

 @media only screen and (max-width: 575px) {
    .leftCardMargin {
        margin-top: 56px !important;
        margin-left: 0 !important;
    }
    .awardYear {
        width: 100%;
    }
    .brandAward {
        width: 100%;
    }
 }

 .home-midSection {
     margin-top: 100px;
 }

 .home-con {
     height: 100%;
 }

 .grantitOption {
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    color: #00345A;
 }

 .calcHeading {
    font-weight: 700;
    font-size: 40px; 
    color: #00345A;
    margin-top: 70px;
    margin-bottom: 30px;
 }

 .awardArea {
     margin: 70px 0;
 }

 .award {
     margin-left: 20px;
 }

 .awardHeading {
    font-weight: 500;
    font-size: 22px;
    color: #00345A;
    line-height: 28px;
    text-align: center;
 }

 .awardDesc {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-top: 16px;
    line-height: 24px;
 }

.homeHeading{
    color: white;
    font-size: 52px;
    margin-top: 10px;
    font-weight: 500;
    line-height: 64px;
}

.home-desc {
    color: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    max-width: 386px;
}

.logo {
    width: 168px;
    height: 168px;
}

 .cardImg {
     width: 100%;
     height: auto;
 }

.app-btns {
    margin-top: 42px;
    display: flex;
}
.download-btns {
    display: flex;
    width: 135px;
    height: 40px;
    background-color: black;
    border-radius: 6px;
    padding: 6px 10px;
}
.apple-icon {
    height: 40px;
    cursor: pointer;
}
.downloadtext {
    font-size:9px;
    margin: 0px 8px !important;
}

.signup {
    padding-left: 20px !important;
}

.down{
    margin: -1px 8px !important;
    font-size: 14px;
    color: white;
}

.quality-img {
    display:"block";
    max-width: "100%";
    height:"auto";
}


@media (max-width: 1099px) {

    .app-btns {
        margin-top: 20px;
    }

}


.homeCardArea {
    margin-top: 100px;
}

.grantitCaracol {
    margin-top: 120px;
}

.slider {
    text-align: center;
    
}

.caracolHeading {
    font-size: 40px;
    font-weight: 500;
    color: #00345A;
}

.caracolLogo {
    height: 49px;
}

.how-works {
    margin-bottom: 30px;
    width: 100%;
}

.works {
    width: 100%;
    background: url('./images/works.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    background-position: top;
    overflow: hidden;
    height: 960px;
}

  @media (max-width: 575px)  { 
    .works {
        width: 100%;
        background: url('./images/how_works_mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        height: 1176px;
    }
    .signupForm {
        width: 100%;
    }
    .key-number {
        background: none !important;
        margin-top: 0 !important;
        padding: 70px !important;
    }
  }


.how-works h1 {
    font-size:40px;
    color: #00345A;
}

.customer-care {
    background: url('./images/carasolimg.svg');
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -62px;
    padding: 130px 0;
}

@media  (max-width: 2560px) { 
    .customer-care {
        padding: 416px 0;
        margin-top: -109px;
    }
}

@media  (max-width: 2000px) { 
    .customer-care {
        padding: 269px 0;
        margin-top: -86px;
    }
}

@media  (max-width: 1800px) { 
    .customer-care {
        padding: 218px 0;
        margin-top: -77px;
    }
}

@media (min-width: 1500px) and (max-width: 1600px) {
    .customer-care {
        height: 900px;
        padding: 166px 0;
        margin-top: -72px;
    }
}

@media (min-width: 1400px) and (max-width: 1499px) {
    .customer-care {
        height: 860px;
        padding: 156px 0;
        margin-top: -67px;
    }
}

@media (min-width: 1600px) {
    .home {
        height: 644px !important;
   }
   .homeScreenStyle {
        margin-left: 300px !important;
   }
}

@media (min-width: 1920px) {
    .home {
        height: 744px !important;
    }
    .homeScreenStyle {
        margin-left: 350px !important;
   }
}


.customer-care h1 {
    font-size:40px;
    color: #fff;
}

.finance-tool {
    padding-top: 82px;
}


.finance-bgImg {
    width: 1300px;
    background: url('./images/financedesign.svg');
    min-height: 565px;
    height: auto;
    background-repeat: no-repeat;
    background-position: center ;
    background-size: contain;

}

@media (max-width: 576px) {
      .finance-bgImg {
          background: none;
          min-height: 0px;
          width: 100%;
      }
} 


.videoArea {
    height: 100%;
}

.finance-tool h1 {
    font-size:40px;
    color: #00345A;
}


.finance-img {
    margin-top: 20px;
    display: block; 
    max-width: 100%; 
    height: auto;
}

.screenshot  {
    display: block; 
    max-width: 100%; 
    height: auto;
}

.grantitVideo {
    width: 100%;
    border-radius: 8px;
    min-height: 420px;
    height: auto;
}

.videoSection {
    margin-top: 50px;
}

/* @media (max-width: 992px) {
  
    .sign-up-responsive {
        margin-right: 0;
        margin-top: 0;
    }
} */

.press {
    cursor: pointer;
}

@media (max-width: 767px) {
    .finance-tool h1 {
        font-size :33px;
    }
    .customer-care h1 {
        font-size: 30px;
    }
    .how-works h1 {
        font-size:33px;
    }
    .press-release h1 {
        font-size:33px;
    }
    .works {
        height: 1176px;
    }
    .mobileLogos {
        display: block;
        margin-bottom: 24px;
    }
    .webLogos {
        display: none;
    }
}

.press-release h1 {
    font-size:40px;
    color: #00345A;
}
@media (max-width: 768px) {
    .grantitCaracol {
        margin-top: 180px;
    }
    .caracolHeading {
        font-size: 32px;
    }

}

@media (max-width: 576px) {
    .press {
        margin-top: 25px;
    }
    .last {
        margin-left: 100px;
    }
    .home-content h3 {
        font-size: 32px;
    }
    .grantitCaracol {
        margin-top: 180px;
    }
}

@media (max-width: 500px) {
    .grantitCaracol {
        margin-top: 96px;
    }
}


.numberText {
    margin-top: 60px;
    text-align: center;
}

.key-number{
    background: url('./images/keynumber.svg');
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 0;
    overflow: hidden;
    background-position: top;
}
.key-number h1 {
    font-size:40px;
    color: #00345A;
}

.rating {
    font-size:16px;
    font-weight:400;
    color: #00345A;
}

.num span {
    margin-top: -22px;
    font-size :56px;
    font-weight: 400;
    background: linear-gradient(to left ,rgba(0, 205, 231, 1), rgba(75, 163, 255, 1));
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.num p {
    margin-bottom: 0;
}

.submitNum {
    height: 44px;
    background: linear-gradient(to left ,rgba(0, 52, 90, 1),rgba(51, 103, 141, 1));
    color: white;
    border-radius: 44px;
    text-align: center;
    font-size: 18px;
    line-height: 44px;
    font-weight: 400;
    cursor: pointer;
    margin: auto;
}

@media only screen and (max-width: 1340px) {
    .homeScreenStyle {
        margin-left : 140px;
    }
}

@media only screen and (max-width: 1200px) {
    .homeScreen {
        width: 400px;
    }
    .homeScreenStyle {
        margin-left: 100px;
    }
}

@media only screen and (max-width: 992px) {
    .homeScreenStyle {
        margin-left: 75px;
    }
    .homeScreen {
        width: 380px;
    }
}

@media only screen and (max-width: 892px) {
    .homeScreenStyle {
        margin-left: 40px;
    }
    .homeScreen {
        width: 360px;
    }
    .cardLogoGrantit {
        margin-left: -10px;
        margin-top: -70px;
        width: 140px;
    }
}

@media only screen and (max-width: 692px) {
    .leftCardHeading {
        font-size: 22px;
        margin-top: 44px;
    }
    .rightCardHeading {
        font-size: 22px;
        margin-top: 44px;
    }
}

@media only screen and (max-width: 625px) {
    .cardLogoGrantit {
        margin-left: -10px;
        margin-top: -66px;
        width: 120px;
    }
}