.blogClick {
    cursor: pointer;
}
.blogimg {
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    border-radius: 5px;
}

.blog-content {
    margin-top: 20px;
}

.blogCategory {
    font-size: 14px;
    font-weight: 400;
    color: #00CDE7;
    margin-bottom: 0px;
}

.blogTitle {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #00345A;
}

.blogMetaDesc {
    font-size: 14px;
    font-weight: 400;
    color: #99B3C6;
    margin-top: 20px;
}