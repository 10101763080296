@import 'styles/colors.scss';
@import 'styles/size.scss';

.alert {
  width: 100%;
  padding: $base-size * 2;
  border-radius: $base-radius * 1.5;

  &.alert-primary {
    background-color: $alert-primary-color;
  }

  &.alert-hide {
    opacity: 0;
  }

  .alertIcon {
    width: 44px;
    height: 52px;
  }
}
