
.howwork p {
    font-size: 16px ;
    font-weight: 500;
    margin-top: 10px;
    color: #00345A;
}

.howwork h6 {
    font-size: 20px;
    color: #00345A;
    margin-top: 10px;
    font-weight: 500;

}
