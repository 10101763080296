@import 'styles/size.scss';
@import 'styles/colors.scss';

.container {
  // nav header height
  padding-top: 88px;

  background: linear-gradient(
    359deg,
    #bbf2f9 -27.33%,
    #ecfcff 45.99%,
    #fafafa 77.75%
  );

  display: flex;
  flex-direction: column;

  // 100px footer -margin and 120px bottom
  padding-bottom: 100px + 120px;

  // 100vh - head - footer
  min-height: calc(100vh - 387px);
}

.center {
  max-width: 993px;
  margin: 0 auto;
  margin-top: $base-size * 5;
  width: 100%;

  border-radius: $base-size * 2.5;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.left {
  padding: $base-size * 5;
  background: $default-bg-color;
}

.right {
  flex: 1;
  padding: $base-size * 5;
  background-color: $white-bg-color;

  display: flex;
  flex-direction: column;
  gap: $base-size * 5;
  justify-items: flex-start;
}

@media (max-width: $web-responsive-width) {
  .center {
    max-width: 560px;
    margin-top: 0px;
    border-radius: 0px;
    flex-direction: column;
  }

  .left {
    padding: $base-size * 2.5 $base-size * 2;
  }

  .right {
    padding: $base-size * 2.5 $base-size * 2;
    margin-top: 0px;
  }
}
