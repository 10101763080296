.dealDetail-margin {
    margin: 150px 0 20px 0;
    display: block;
}

.backIcon {
    width: 16px;
}

.dealCaption h1 {
    font-size: 56px;
    font-weight: 400;
    background: linear-gradient(#00CDE7 15.42%, #4BA3FF 87.08%);
    background: -webkit-linear-gradient(#00CDE7 15.42%, #4BA3FF 87.08%);
    background-clip: border-box;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.dealBanner {
    margin: 0 10px 100px 10px;
}

.dealBannerImg {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.dealCaption p {
    font-size: 16px;
    font-weight: 400;
    color: #00345A;
    margin-top: 20px;
}

.back {
    cursor: pointer;
    color: #8E9599;
    display: flex;
    align-items: center;
    gap: 4px;
}

.dealDetail {
    margin-top: 0px;
    padding-bottom: 152px;
}

.dealDetail h1 {
    margin-top: 12px;
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
    color: #00345A;
    text-align: left;
}

.dealDetail p {
    text-align: left;
    color: #354147;
    font-size: 18px;
}

.dealExpiry {
    color: #00B9D0;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
}

.dealDetailDesc * {
    margin-top: 20px;
    font-weight: 400;
    line-height: 32px;
    color: #354147;
}

.dealDetailDesc strong {
    font-weight: bolder !important;
}

.promoDetail {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.promoDetail p {
    font-size: 18px;
    font-weight: 600;
    color: #697175;
    line-height: 36px;
}

.promoCode {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    background: #EDF1F2;
    border-radius: 12px;
    width: max-content !important;
    height: 82px;
    width: 270px;
    padding: 19px 34px 19px 50px;
}

.promoCode p {
    font-size: 28px;
    color: #4BA3FF;
    font-weight: 500;
    line-height: 44px;
    height: auto;
    margin-right: 16px;
}

.dealDetailButton {
    margin: 40px 0 0 0;
    color: white;
    width: max-content;
    min-width: 180px;
    font-weight: 400;
    height: 44px;
    font-size: 18px;
    line-height: 24px;
    background: linear-gradient(274.24deg, #00345A 9.14%, #33678D 93.91%);
    border: 0px;
    border-radius: 40px;
    cursor: pointer;
}

.dealTnC * {
    font-size: 12px !important;
    font-weight: 450 !important;
    line-height: 16px !important;
    color: #697175 !important;
    align-items: center !important;
}

.mobileOtherOffers {
    display: none;
}

.mobileBanner {
    display: none;
}

.mobileprops {
    margin-top: 0;
}

.copyToast {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 30px;
    z-index: 10;
}

.toastContent {
    align-self: center;
    justify-content: center;
    background-color: #000000;
    border-radius: 50px;
    display: flex;
    width: max-content;
    padding: 6px;
    flex-direction: row;
}

@media (max-width: 576px) {

    .mobileBanner {
        display: flex;
        margin: 60px 0 0 0;
        height: 300px;
    }

    .dealBannerMobImg {
        width: 100%;
        height: auto;
        object-fit: cover;
        z-index: -1;
    }

    .dealDetail-margin {
        display: none;
    }

    .bannerCol {
        display: none;
    }

    .dealDetailButton {
        width: 100%;
    }

    .mobileprops {
        margin-top: -25px;
        border-radius: 25px;
        background: white;    
    }

    .dealDetail {
        margin-top: 40px;
    }

    .mobileOtherOffers {
        display: block;
        margin-top: 12px;
        width: 100%;
        font-size: 18px;
        line-height: 24px;
        border-radius: 40px;
        font-weight: 400;
        height: 44px;
        background: transparent;
        color: #697175;
        border: 1px solid #697175;

    }

    .copyToast {
        width: 100%;
    }
}