
  .slick {
      min-height: 360px;
  }
  .slick-slide {
	padding: 0 8px;
	box-sizing: border-box;
    height: 313px !important;
    margin-top: 25px;
 }

 .slick-center {
     margin-top: 0px;
     height: 360px !important;
     /* transition: all 0.5s ease-out; */
 }

 .slick-center .card {
     height: 360px !important;
 }
  
 @media (max-width: 768px) {
     .slick-prev {
         left: 40% !important;
     }
     .slick-next {
        right: 43% !important;
     }
 }

@media (max-width: 1024px) {
    .slick-prev {
        left: 47% !important;
    }
}

@media (min-width: 768px) and (max-width: 1023.90px) { 
    .slick-prev {
        left: 46% !important;
    }
    .slick-next {
        right: 46% !important;
    }
  }

.slick-prev:before, .slick-next:before {
    font-size: 0px;
    line-height: 1;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 425px) {
    .slick-slide {
        margin-top: 0px;
    }
    .slick-center {
       height: 313px;
    }
    .slick-center .card {
        height: 313px !important;
    }
    .slick-prev {
        left: 41% !important;
    }
    .slick-next {
        right: 41% !important;
    }
    .slick-arrow {
        top: 99% !important;
    }
}

@media (min-width: 426px) and (max-width: 576px) {
    .slick-prev {
        left: 40% !important;
    }
    .slick-next {
        right: 45% !important;
    }
 }