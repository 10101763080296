$hero1-font-size: 40px;
$hero1-line-height: 46px;

$xh1-font-size: 32px;
$xh1-line-height: 44px;

$h1-font-size: 26px;
$h1-line-height: 32px;

$h2-font-size: 22px;
$h2-line-height: 27px;

$h3-font-size: 20px;
$h3-line-height: 24px;

$h4-font-size: 18px;
$h4-line-height: 22px;

$title1-font-size: 16px;
$title1-line-height: 20px;

$title2-font-size: 14px;
$title2-line-height: 18px;

$body1-font-size: 16px;
$body1-line-height: 20px;

$body2-font-size: 14px;
$body2-line-height: 18px;

$body3-font-size: 12px;
$body3-line-height: 16px;

$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
