@import 'styles/size.scss';

.spacer {
  margin: 0px;
  padding: 0px;

  @for $i from 0 through 48 {
    &.vertical-#{$i} {
      height: $i * $base-size / 2;
    }

    &.horizontal-#{$i} {
      width: $i * $base-size / 2;
    }
  }
}

@media (max-width: $web-responsive-width) {
  .spacer {
    @for $i from 0 through 48 {
      &.vertical-lg-#{$i} {
        height: $i * $base-size / 2;
      }

      &.horizontal-lg-#{$i} {
        width: $i * $base-size / 2;
      }
    }
  }
}
