@import 'styles/size.scss';
@import 'styles/colors.scss';

.steps {
  margin: 0px;
  padding: 0px;

  display: flex;
  flex-direction: column;
  gap: 0px;

  .separator {
    display: block;
    margin-left: 13px;
    width: 2px;
    height: 20px;
    background: $separator-bg-color;
  }

  .node {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $base-size * 2.25;

    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      text-align: center;
      background-color: $tag-weaken-bg-color;

      &.active {
        background-color: $tag-active-bg-color;
      }
    }

    .checkmark {
      width: 30px;
      height: 30px;
    }
  }
}

@media (max-width: $web-responsive-width) {
  .steps {
    flex-direction: row;
    align-items: center;
    gap: $base-size * 1.5;

    .separator {
      display: none;

      &.pass {
        display: block;
        margin-left: $base-size * -1.5;
        margin-right: $base-size * -1.5;
        height: 4px;
        width: 10px;
      }
    }

    .node {
      gap: $base-size * 0.75;

      .textPass {
        display: none;
      }

      .tag {
        width: 24px;
        height: 24px;

        border-radius: 12px;
      }

      .checkmark {
        width: 24px;
        height: 24px;
      }
    }
  }
}
