@import 'styles/font.scss';
@import 'styles/size.scss';
@import 'styles/colors.scss';

.input {
  width: 100%;
  padding: $base-size * 2;

  border: 1px solid $input-default-border-color;
  border-radius: $base-radius;

  font-size: $h4-font-size;
  font-weight: $font-normal;
  line-height: $h4-line-height;

  color: $text-title-color;

  &::placeholder {
    color: $text-inactive-color;
  }

  &:focus {
    outline: none !important;
    border: 1px solid $input-active-border-color;
  }

  &.prefix {
    padding-left: $base-size * 4;
  }

  &.error {
    border: 1px solid $input-error-border-color;
  }
}

.inputContainer {
  position: relative;
  width: 100%;
}

.inputPrefix {
  position: absolute;
  left: $base-size * 2;
  top: 50%;
  transform: translateY(-50%);
}
