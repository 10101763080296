.deals-margin {
    margin-top: 148px;
}

.deals h1 {
    font-size: 52px;
    font-weight: 400;
    color: #00345A;
    text-align: center;
}

.deals p {
    font-size: 16px;
    font-weight: 400;
    color: #00345A;
    margin-top: 20px;
}

.dealCategory {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dealsList {
    margin: 53px 50px 250px 100px; 
}

.categoryButton {
    min-width: 120px;
    background: transparent !important;
    color: #4BA3FF !important;
    border-color: #4BA3FF !important;
}

.categoryButton:hover {
    background: #4BA3FF !important;
    color: white !important;
    border-color: #4BA3FF !important;
}

.categoryButton.active {
    background: #4BA3FF !important;
    color: white !important;
    border-color: #4BA3FF !important;
}

@media (max-width: 767px) {
    .categoryButton {
        min-width: max-content;
        font-size: 14px;
    }
    .deals h1 {
        font-size: 30px !important;
    }
}

@media (max-width: 576px) {

    .deals-margin {
        margin-top: 108px;
    }

    .deals h1 {
        font-size: 22px !important;
        font-weight: 500;
        line-height: 20px;
    }

    .dealCategory {
        display: block;
    }

    .categoryButton {
        min-width: 64px;
        padding: 5px 7px 5px 7px !important;
        margin: 7px 5px !important;
        background: transparent !important;
        color: #4BA3FF !important;
        border-color: #4BA3FF !important;
        font-size: 14px;
        font-weight: 500;
    }

    .dealsList {
        margin: 22px auto 88px auto;
        align-items: center;
    }
}